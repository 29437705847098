
import { defineAsyncComponent, defineComponent } from 'vue'
import DynamicTableMenu from './DynamicTableMenu.vue'
import BaseIcon from '@/shared/components/base-icon/BaseIcon.vue'
import Pagination from '@/shared/components/Pagination.vue'
import { ReportActions } from '@/store/modules/reports/actions'
import TableGhost from '@/shared/components/ghosts/TableGhost.vue'
import router from '@/router'

export default defineComponent({
  components: {
    TableGhost,
    BaseIcon,
    DynamicTableMenu,
    Pagination,
    Popper: defineAsyncComponent(() => import('vue3-popper'))
  },
  data () {
    return {
      current: 1,
      height: 150,
      columns: [] as any,
      data: [] as any,
      exportLoading: false
    }
  },
  emits: ['onClickLink'],
  props: {
    stickyFooter: {
      type: Boolean,
      default: false
    },
    reportType: {
      type: String
    },
    tableFields: {
      type: Array
    },
    tableData: {
      type: Array
    },
    exportData: {
      type: Array
    },
    buttons: {
      type: Object
    },
    loading: {
      type: Boolean
    },
    pagination: {
      type: Object
    },
    exportFields: {
      type: Array
    },
    scrollable: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.height = document.documentElement.clientHeight - 300
    addEventListener('resize', () => {
      this.height = document.documentElement.clientHeight - 300
    })
  },
  methods: {
    exportTableToExcel (event: any) {
      event.stopPropagation()
      this.exportLoading = true
      this.$store.dispatch(`reports/${[ReportActions.FETCH_REPORTS]}`, { reportType: this.reportType, export: true, pageNumber: 1, pageSize: this.pagination?.totalItems || 100, ...this.$route.query }).then((res: any) => {
        const button = document.getElementById('excel-export')
        this.columns = this.exportFields?.map((el: any) => {
          return { label: el.name, field: el.id }
        })
        // eslint-disable-next-line no-unused-expressions
        this.data = this.exportData?.map((item: any) => {
          const obj: any = {}
          // eslint-disable-next-line no-unused-expressions
          this.exportFields?.forEach((field: any) => {
            obj[field.id] = item[field.id]
          })
          return obj
        })
        if (button) {
          setTimeout(() => {
            button.click()
            this.exportLoading = false
          }, 200)
        }
      })
    },
    getBindings (items:any, dataRow:any) {
      let map = {}
      Object.keys(items).map((k) => {
        map = { ...map, [k]: items[k] === 'rowData' ? dataRow : dataRow[items[k]] }
      })
      return map
    }
  },
  watch: {
    current (val) {
      router.push({ query: { ...this.$route.query, pageNumber: val } })
    },
    $route: {
      immediate: true,
      handler (val) {
        this.current = val.query.pageNumber ? Number(this.$route.query.pageNumber) : 1
      }
    }
  }

})

